import { ColumnDef } from '@tanstack/react-table';
import FirestoreTable from 'components/base/FirestoreTable';
import FirestoreTableFooter from 'components/base/FirestoreTableFooter';
import Rating from 'components/base/Rating';
import { Product } from 'data/krakken/products';
import { currencyFormat } from 'helpers/utils';
import { useFirestoreTableContext } from 'providers/FirebaseTableProvider';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import dayjs from 'dayjs';
import { DocumentDataIdentifiable } from 'hooks/useFirestore';

export const productsTablecolumns: ColumnDef<
  DocumentDataIdentifiable<Product>
>[] = [
  {
    id: 'productImage',
    accessorKey: '',
    cell: ({ row: { original } }) => {
      const imageUrl = original['Image URL'];
      const productUrl = original['URL'];
      return (
        <Link
          to={productUrl}
          className="rounded-2 border d-inline-block"
          target="_blank"
        >
          {imageUrl && <img src={imageUrl} alt="" width={150} />}
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: 70 } },
      cellProps: { className: 'py-0' }
    },
    enableSorting: false
  },
  {
    accessorKey: 'Title',
    header: 'Produit',
    cell: ({ row: { original } }) => {
      const { Title, URL, id, Price, Brand } = original;
      return (
        <Link
          className="fw-semi-bold line-clamp-3 text-black product-title"
          to={URL}
          target="_blank"
          data-id={id}
          data-name={Title}
          data-price={Price ? Price / 100 : undefined}
          data-brand={Brand}
        >
          {Title} <FeatherIcon icon="external-link" size={12} />
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: 350 }, className: 'ps-4' },
      cellProps: { className: 'ps-4' }
    }
  },
  {
    id: 'Price',
    header: 'Prix',
    accessorKey: 'Price',
    cell: ({ row: { original } }) => {
      const { Price } = original;
      return Price ? currencyFormat(Price / 100) : null;
    },
    meta: {
      headerProps: { style: { width: 30 }, className: 'ps-4 text-end' },
      cellProps: { className: 'ps-4 text-700 text-end' }
    }
  },
  {
    id: 'Last Assessment',
    header: 'Date',
    accessorKey: 'Last Assessment',
    cell: ({ row: { original } }) => {
      const assessment = original['Last Assessment']?.toDate();

      if (assessment) return dayjs(assessment).format('DD/MM/YYYY');
    },
    meta: {
      headerProps: { style: { width: 50 }, className: 'ps-4 text-end' },
      cellProps: { className: 'text-600 ps-4 text-end' }
    }
  },
  {
    id: 'Seller',
    header: 'Vendeur',
    accessorKey: 'Seller.Title',
    cell: ({ row: { original } }) => {
      return original?.Seller?.Title;
    },
    meta: {
      headerProps: { style: { width: 50 }, className: 'ps-4 text-center' },
      cellProps: { className: 'ps-4 text-center' }
    }
  },
  {
    id: 'Brand',
    header: 'Marque',
    accessorKey: 'Brand',
    cell: ({ row: { original } }) => {
      return original?.Brand;
    },
    meta: {
      headerProps: { style: { width: 50 }, className: 'ps-4 text-center' },
      cellProps: { className: 'ps-4 text-center' }
    }
  },
  {
    id: 'Seller Count',
    header: 'Offres',
    accessorKey: 'Seller Count',
    cell: ({ row: { original } }) => {
      const count = original['Seller Count'];
      if (!count) return null;
      else return `${count} ${count > 1 ? 'offres' : 'offre'}`;
    },
    meta: {
      headerProps: { style: { width: 125 }, className: 'ps-4 text-center' },
      cellProps: { className: 'ps-4 text-center' }
    }
  },
  {
    id: 'Review rate',
    header: 'Note',
    accessorKey: 'Review rate',
    cell: ({ row: { original } }) => {
      return <Rating readonly initialValue={original['Review rate']} />;
    },
    meta: {
      headerProps: { style: { width: 125 }, className: 'ps-4 text-center' },
      cellProps: { className: 'ps-4 text-center' }
    }
  }
];

const ProductsTable = () => {
  const { table, loading } = useFirestoreTableContext();
  return (
    <div>
      {loading && <Spinner />}
      {!loading && table && (
        <div>
          <FirestoreTable tableProps={{ className: 'phoenix-table fs-12' }} />
          <FirestoreTableFooter navBtn showViewAllBtn={false} />
        </div>
      )}
    </div>
  );
};

export default ProductsTable;
